import { render, staticRenderFns } from "./OrganizationManagement.vue?vue&type=template&id=ba128546&"
import script from "./OrganizationManagement.vue?vue&type=script&lang=js&"
export * from "./OrganizationManagement.vue?vue&type=script&lang=js&"
import style0 from "./OrganizationManagement.vue?vue&type=style&index=0&id=ba128546&prod&lang=less&scope=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports